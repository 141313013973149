<template>
  <div id="historyVehicle" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between">
        <div class="N-Page T-size-36">History Vehicle</div>
        <div class="box-S2 flex-between noPadding">
          <div class="box-S2 noPadding">
            <v-select
              class="hideMessage"
              v-model="selectolicenseplate"
              :items="dataselect"
              label="License Plate"
              @change="renderline(selectolicenseplate)"
            ></v-select>
          </div>
          <div class="box-S2 noPadding">
            <v-menu
              class="hideMessage"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="0"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field v-model="date" class="hideMessage input-date" append-icon="mdi-calendar-range" label="Select Date" readonly v-on="on"></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @change="renderline(selectolicenseplate,date)"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
    <div id="historyMap" class="wrap-Main">
      <div class="box-S4">
        <l-map :zoom="zoom" :center="center" style="z-index: 0;height: 65vh;">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker :lat-lng="center" :icon="iconcenter" />
          <l-marker
            v-for="(item, index) in markers"
            :key="'marker-' + index"
            :lat-lng="item.location"
            :icon="icon"
          >
            <l-popup>{{item.name}}</l-popup>
          </l-marker>
          <l-polyline :lat-lngs="latlngs" color="#0BCC01"></l-polyline>
        </l-map>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { latLng, icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LPolyline, LPopup } from "vue2-leaflet";
import { startOfDay, endOfDay, format, addHours } from "date-fns";
import feathersClientVan from "../plugins/feathers-client";

export default {
  name: "Example",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LPopup
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      zoom: 13,
      center: L.latLng(7.1874908, 100.6022255),
      url:"http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      markers: [],
      icon: icon({
        // iconUrl: "http://203.151.121.245:9062/images/car.png",
        iconUrl: "https://api.prodigyfly.com/uploads/car.svg",
        iconSize: [28, 60],
        iconAnchor: [14, 30]
      }),
      iconcenter: icon({
        // iconUrl: "https://image.flaticon.com/icons/png/512/1518/1518388.png",
        iconUrl: "https://api.prodigyfly.com/uploads/airport.svg",
        iconSize: [32, 32],
        iconAnchor: [16, 16]
      }),
      latlngs: [],
      dataselect: [],
      dataraw: [],
      selectolicenseplate: "นข4557 สงขลา"
    };
  },
  async mounted() {
    this.renderSelect();
    this.renderline();
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    async renderSelect() {
      try {
        const q = {};
        var res = await feathersClientVan
          .service("onlinevan")
          .find({ query: q });
        this.markers = [];
        res.data.forEach(element => {
          this.dataselect.push(element.o_licenseplate);
        });
      } catch (err) {
        this.$toast.error("ไม่สามารถติดต่อ server ได้" + err);
      }
    },
    async renderline(licenseplate, date) {
      if (licenseplate == null) {
        licenseplate = "นข4557 สงขลา";
      }
      this.menu = false;
      try {
        var sDate = startOfDay(new Date(this.date));
        var eDate = endOfDay(new Date(this.date));
        var x = format(addHours(sDate, 0), "yyyy-MM-dd HH:mm:ss");
        var y = format(addHours(eDate, 0), "yyyy-MM-dd HH:mm:ss");
        const q = {
          t_datetime: {
            $gt: x,
            $lt: y
          },
          t_licenseplate: licenseplate,
          $sort: {
            t_datetime: -1
          }
        };
        var res = await feathersClientVan
          .service("tracking")
          .find({ query: q });
        this.latlngs = [];
        res.data.forEach(element => {
          this.latlngs.push(
            eval("[" + element.t_lat + "," + element.t_lng + "]")
          );
        });

        // alert(JSON.stringify(this.latlngs))

        this.markers = [];
        var c = {};
        c["location"] = eval(
          "L.latLng(" + res.data[0].t_lat + "," + res.data[0].t_lng + ")"
        );
        c["name"] = res.data[0].t_licenseplate
        this.markers.push(c);
      } catch (err) {
        //this.$toast.error("ไม่สามารถติดต่อ server ได้" + err);
        // alert(err);
      }
    }
  }
};
</script>


<style>
</style>